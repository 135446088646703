<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="1450px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.QUOTATION_CATEGORY") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :disabled="languages.length == 1"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <!-- :items="statuses.quotations.quotation" -->
              <v-select
                v-if="statuses.quotations"
                v-model="formModel.status"
                :items="statuses.pages.page"
                label="Status"
                item-text="value"
                item-value="key"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].name"
                :rules="nameRules"
                :label="$t('FORMS.name')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.name']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.name'] = ''
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-select
                v-model="formModel.quotations"
                :items="quotationCollection"
                chips
                :label="$t('MENU.QUOTATIONS')"
                deletable-chips="true"
                item-text="name"
                item-value="id"
                multiple
                solo
              >
                <template #selection="{ item }">
                  <v-chip
                    color="bg-gray-100"
                    close
                    @click:close="deleteChip(item, formModel.quotations)"
                    >{{ item.name }}</v-chip
                  >
                </template>
              </v-select>
            </v-col>
          </v-row>
          <draggable
            tag="v-row"
            v-model="draggableCards"
            style="display: flex; flex-direction: row"
          >
            <v-col
              v-for="file in files"
              :key="file.path"
              class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12"
            >
              <v-card>
                <v-img
                  v-if="previewable_mime_types.includes(file.file.mime_type)"
                  :src="getFile(file.file)"
                  height="175"
                  style="cursor: pointer"
                  :title="file.file.name"
                ></v-img>
                <a v-else :href="getFile(file.file)" target="_blank">
                  <v-img
                    :src="getIcon(file.file)"
                    :title="file.file.name"
                    height="175"
                    contain
                  />
                </a>
                <v-card-actions style="height: 40px">
                  {{ file.name }}
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
          </draggable>
        </v-form>
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./QuotationCategories";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
// import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
import draggable from "vuedraggable";

const TRANSLATED_ATRIBUTES = ["name"];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [];

export const initialFormData = () => ({
  id: null,
  status: 1,
  quotations: [],
  translations: {},
  custom_fields: [],
});

export default {
  name: "QuotationCategoryForm",
  props: ["modalData", "permissions", "statuses", "endPoint"],
  components: { draggable },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
      previewable_mime_types: [
        "image/png",
        "image/jpeg",
        "image/x-ms-bmp",
        "image/webp",
        "image/gif",
        "image/svg+xml",
      ],
      files: [],
    };
  },
  computed: {
    ...mapGetters(["quotationCollection"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") + " " + this.$t("MENU.QUOTATION_CATEGORY");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },

    draggableCards: {
      get() {
        return this.files;
      },
      set(val) {
        this.files = val;
      },
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.userLanguages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
              // this.formModel.custom_fields = this.initCustomFields();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();

          // this.formModel.custom_fields = this.initCustomFields();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
    "formModel.quotations": function (newVal) {
      this.files = this.calculateFiles(newVal);
    },
  },
  methods: {
    ...mapActions(["fetchQuotation"]),
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );
      model.quotations = this.files.map((file) => file.id);
      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;

        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    calculateFiles() {
      if (!this.formModel.quotations) return [];

      let f = [];
      let quotation;
      this.formModel.quotations.forEach((id) => {
        quotation = this.quotationCollection.find(
          (quotation) => quotation.id == id
        );

        quotation.name = quotation.translations[this.selectedLocale.lang].name;

        let noImage = {
          name: "no_image.png",
          path: "img/no_image.png",
          size: 1,
          user: "",
          mime_type: "image/jpeg",
          created_at: "2022-01-19 13:27:07",
        };
        if (
          !quotation.translations[this.selectedLocale.lang].custom_fields
            .image ||
          !quotation.translations[this.selectedLocale.lang].custom_fields
            .image[0]
        ) {
          quotation.file = noImage;
        } else {
          quotation.file =
            quotation.translations[
              this.selectedLocale.lang
            ].custom_fields.image[0];
        }

        f.push(quotation);
      });

      return f;
    },

    getFile(file) {
      return process.env.VUE_APP_BACKEND_URL + "/" + file.path;
    },
    deleteChip(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove.id) {
          array.splice(i, 1);
        }
      }
    },
  },

  mounted() {
    this.fetchQuotation();
    this.setTranslatedAttributes();
  },
};
</script>
